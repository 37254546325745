import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../../components/adminPage';
import { getResourceText } from '../../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../../components/loading';
import { useApi } from '../../../../../../hooks/api';
import LabelInput from '../../../../../../components/labelInput';
import { googleDocsFormat } from '../../../../../../lib/url';

const AdminEditSection = ({ resourcePackId, sectionId }) => {
  const [sectionName, setSectionName] = useState('');
  const [currentSectionName, setCurrentSectionName] = useState();
  const [teacherNotesURLError, setTeacherNotesURLError] = useState(false);
  const [resourcePack, setResourcePack] = useState();
  const [teacherNotesURL, setTeacherNotesURL] = useState('');
  const [sectionNameError, setSectionNameError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const { admin: { updateSection: apiUpdateSection, getSection, getResourcePack } } = useApi();

  useEffect(() => {
    if (resourcePackId && sectionId) {
      const getData = async () => {
        const [
          { status, json },
          { status: sectionResponseStatus, json: section }
        ] = await Promise.all([getResourcePack(resourcePackId), getSection({ resourcePackId, sectionId })]);

        if (status === 200 && sectionResponseStatus === 200) {
          setResourcePack(json);
          setSectionName(section.resourceName);
          setCurrentSectionName(section.resourceName);
          setTeacherNotesURL(section.teacherNotesURL);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [getResourcePack, getSection, resourcePackId, sectionId]);

  const validate = () => {
    let isValid = true;

    if (!sectionName?.trim()) {
      isValid = false
      setSectionNameError(true);
    } else {
      setSectionNameError(false);
    }

    if (teacherNotesURL && !googleDocsFormat.test(teacherNotesURL)) {
      setTeacherNotesURLError(true);
      isValid = false;
    } else {
      setTeacherNotesURLError(false);
    }

    return isValid;
  };


  const updateSection = async (event) => {
    event.preventDefault();
    setApiError(false);
    const isValid = validate();

    if (isValid) {
      setLoading(true);

      const { status } = await apiUpdateSection({ resourcePackId, sectionId, sectionName, teacherNotesURL });

      if (status === 200) {
        await navigate(`/admin/resource-packs/${resourcePackId}/edit`);
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-create-section-heading">{resourcePack?.resourceName}</h2>
      <h3 className="admin-create-section-heading-smaller">{currentSectionName}</h3>
      <div className="admin-create-section-container">
        <h2 className="admin-create-section-container__heading">
          <span className="admin-create-section-container__heading-text">
            {getResourceText('updateLessonGroup')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-section-container__body-container">
          <form onSubmit={(event) => updateSection(event)}>
            <LabelInput labelKey="name" value={sectionName} onChange={setSectionName} classNamePrefix="admin-create-section-container" error={sectionNameError} errorCode="sectionNameError"/>
            <LabelInput labelKey="sectionNotesURL" value={teacherNotesURL} onChange={setTeacherNotesURL} classNamePrefix="admin-create-section-container" errorCode="teacherNotesURLError" error={teacherNotesURLError} />
            <div className="admin-create-section-container__actions">
              {apiError ? <span
                className="admin-create-section-container__action-error">{getResourceText('updateSectionApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-section-container__action"
                      onClick={async () => await navigate(`/admin/resource-packs/${resourcePackId}/edit`)}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-section-container__action">{getResourceText('updateLessonGroup')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminEditSection;