import React from 'react';
import { getResourceText } from '../lib/language';
import classNames from 'classnames';
// import Icon, { GlyphNames } from './icon';

const LabelInput = ({ value = '', onChange, labelKey, error = false, errorCode, classNamePrefix = '', readOnly = false, withCopyButton }) => {
  // const [copied, setCopied] = useState(false);
  //
  // const copyToClipboard = async (event) => {
  //   event.preventDefault();
  //
  //   if (window) {
  //     try {
  //       await window.navigator.clipboard.writeText(value);
  //       setCopied(true);
  //     } catch (e) {
  //       console.log('Error copying to clipboard', e);
  //     }
  //   }
  //   return false;
  // };

  return (
    <div className={`${classNamePrefix}__label-inputs-container`}>
      <label className={`${classNamePrefix}__label-input-container`}>
        <span className={`${classNamePrefix}__label`}>{getResourceText(labelKey)}</span>
        <div className={classNames(`${classNamePrefix}__input-container`, {
          [`${classNamePrefix}__input-container-with-button`]: withCopyButton
        })}>
          <input type="text" className={classNames(`${classNamePrefix}__input`, {
            [`${classNamePrefix}__input-with-button`]: withCopyButton
          })} value={value} onChange={!readOnly ? ({ target: { value: updatedValue } }) => onChange(updatedValue) : undefined} readOnly={readOnly}/>
          {/*{withCopyButton && window?.navigator ? (*/}
          {/*  <button className="btn admin-btn" onClick={(event) => copyToClipboard(event)} aria-label="Copy field">*/}
          {/*    <Icon glyphName={GlyphNames.copy}/>*/}
          {/*  </button>) : null}*/}
          {/*{withCopyButton && copied ? <span style={{ marginLeft: '12px', display: 'flex', alignItems: 'center' }}>Copied!</span> : null}*/}
        </div>
      </label>
      {error && !errorCode ? <span className={`${classNamePrefix}__error`}>{getResourceText(`${labelKey}Error`)}</span> : null}
      {error && errorCode ? <span className={`${classNamePrefix}__error`}>{getResourceText(errorCode)}</span> : null}
    </div>
  );
};

export default LabelInput;